const abi = `{
	"ABI version": 2,
	"header": ["time"],
	"functions": [
		{
			"name": "onGetStats",
			"inputs": [
				{"components":[{"name":"TVL","type":"uint64"},{"name":"remainingBonus","type":"uint64"},{"name":"paidBonus","type":"uint64"},{"name":"participantQty","type":"uint32"},{"name":"depoolQty","type":"uint32"},{"name":"totalStakeQty","type":"uint32"},{"name":"lockedStakeQty","type":"uint32"},{"name":"periodSlice","type":"map(uint8,uint32)"},{"name":"stakeSlice","type":"map(uint8,uint32)"}],"name":"stats","type":"tuple"}
			],
			"outputs": [
			]
		}
	],
	"data": [
	],
	"events": [
	]
}`;

export default {
    abi
}