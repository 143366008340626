import { FunctionComponent, useState, useRef } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import cn from "classnames";

const Layout: FunctionComponent<{elementsScrolled: any}> = ({elementsScrolled}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const sectionRefs = [
    useRef<HTMLDivElement>(null),
  ];

  return (
        <Container fluid={"md"} className="logo-wrapper">
          <header id="header" className={expanded ? "active" : undefined}>
            <Navbar expand="sm" expanded={expanded}>
            <Navbar.Brand>
              <a href="https://everscale.network" target="_blank" rel="noreferrer" onClick={() => setExpanded(false)}  className={cn("logo", {"scrolled": elementsScrolled[0]})}>
                <svg width="216" height="58" viewBox="0 0 216 58" fill="none">
                  <g>
                    <path d="M61.7187 21.8805C59.9977 20.1145 59.1372 17.9471 59.1372 15.3783C59.1372 12.8096 59.9977 10.6536 61.7187 8.91055C63.4626 7.14449 65.5851 6.26147 68.0863 6.26147C70.5875 6.26147 72.6986 7.14449 74.4196 8.91055C76.1635 10.6536 77.0355 12.8096 77.0355 15.3783V16.5824H62.7513C63.0266 18.0273 63.6577 19.1627 64.6444 19.9884C65.6311 20.814 66.7784 21.2269 68.0863 21.2269C69.6926 21.2269 70.9776 20.8255 71.9413 20.0227C72.3773 19.7016 72.756 19.3003 73.0772 18.8186H76.8634C76.3815 19.9425 75.7733 20.8943 75.0391 21.6741C73.1575 23.5548 70.8399 24.4952 68.0863 24.4952C65.5851 24.4952 63.4626 23.6236 61.7187 21.8805ZM62.9234 13.6582H73.2493C73.0428 12.5113 72.492 11.5367 71.5972 10.7339C70.7252 9.93116 69.5549 9.52979 68.0863 9.52979C66.8473 9.52979 65.7458 9.9082 64.7821 10.6651C63.8183 11.399 63.1988 12.3967 62.9234 13.6582Z" fill="currentColor"/>
                    <path d="M78.4148 6.60547H82.201L85.9873 20.8828H88.2242L92.0109 6.60547H95.7968L90.9777 24.1511H83.2336L78.4148 6.60547Z" fill="currentColor"/>
                    <path d="M99.7685 21.8805C98.0474 20.1145 97.1873 17.9471 97.1873 15.3783C97.1873 12.8096 98.0474 10.6536 99.7685 8.91055C101.513 7.14449 103.635 6.26147 106.136 6.26147C108.637 6.26147 110.748 7.14449 112.469 8.91055C114.213 10.6536 115.085 12.8096 115.085 15.3783V16.5824H100.801C101.077 18.0273 101.707 19.1627 102.694 19.9884C103.681 20.814 104.828 21.2269 106.136 21.2269C107.742 21.2269 109.027 20.8255 109.991 20.0227C110.427 19.7016 110.806 19.3003 111.127 18.8186H114.913C114.431 19.9425 113.823 20.8943 113.089 21.6741C111.207 23.5548 108.89 24.4952 106.136 24.4952C103.635 24.4952 101.513 23.6236 99.7685 21.8805ZM100.973 13.6582H111.299C111.093 12.5113 110.542 11.5367 109.646 10.7339C108.775 9.93116 107.605 9.52979 106.136 9.52979C104.897 9.52979 103.796 9.9082 102.832 10.6651C101.868 11.399 101.249 12.3967 100.973 13.6582Z" fill="currentColor"/>
                    <path d="M118.185 24.1512V6.60561H121.8V9.18583H121.972C122.224 8.70418 122.592 8.24549 123.073 7.80972C124.129 6.8923 125.368 6.43359 126.79 6.43359H128.856V9.87394H126.618C125.219 9.87394 124.06 10.3212 123.142 11.2157C122.247 12.0872 121.8 13.2454 121.8 14.6903V24.1512H118.185Z" fill="currentColor"/>
                    <path d="M130.393 18.6466H134.351C134.511 19.1054 134.798 19.5411 135.211 19.9539C136.06 20.8025 137.207 21.2269 138.653 21.2269C139.915 21.2269 140.833 21.0319 141.407 20.642C141.981 20.2521 142.267 19.7589 142.267 19.1627C142.267 18.9562 142.222 18.7728 142.13 18.6122C142.038 18.4287 141.877 18.2682 141.648 18.1306C141.418 17.993 141.2 17.8783 140.994 17.7865C140.788 17.6719 140.477 17.5687 140.064 17.4769C139.652 17.3623 139.319 17.282 139.066 17.2361C138.814 17.1673 138.424 17.0755 137.896 16.9609C137.391 16.8462 137.013 16.7659 136.76 16.7201C133.089 15.8944 131.253 14.1283 131.253 11.4219C131.253 10.0458 131.884 8.84176 133.146 7.80962C134.408 6.77752 136.072 6.26147 138.137 6.26147C141.005 6.26147 143.151 7.17889 144.573 9.0137C145.216 9.81645 145.652 10.7339 145.882 11.766H142.095C141.912 11.3073 141.66 10.9403 141.338 10.6651C140.535 9.9082 139.468 9.52979 138.137 9.52979C137.104 9.52979 136.324 9.7133 135.796 10.0802C135.292 10.4472 135.039 10.9059 135.039 11.4564C135.039 11.6628 135.085 11.8578 135.177 12.0412C135.269 12.2017 135.43 12.3508 135.659 12.4885C135.888 12.6261 136.106 12.7408 136.313 12.8324C136.542 12.9242 136.852 13.0274 137.242 13.1422C137.632 13.2338 137.965 13.3141 138.241 13.3829C138.516 13.4288 138.894 13.5091 139.377 13.6237C139.881 13.7155 140.271 13.7957 140.547 13.8645C144.218 14.6902 146.054 16.4563 146.054 19.1627C146.054 20.6305 145.388 21.892 144.058 22.947C142.749 23.9791 140.948 24.4952 138.653 24.4952C135.67 24.4952 133.387 23.5204 131.804 21.5709C131.093 20.6764 130.622 19.7016 130.393 18.6466Z" fill="currentColor"/>
                    <path d="M150.624 21.9149C148.948 20.1948 148.111 18.0159 148.111 15.3783C148.111 12.7408 148.948 10.5619 150.624 8.84176C152.298 7.12155 154.386 6.26147 156.888 6.26147C159.687 6.26147 161.97 7.32797 163.737 9.46099C164.495 10.4472 165.022 11.5022 165.321 12.6261H161.535C161.374 12.0527 161.076 11.5367 160.639 11.0779C159.744 10.0458 158.494 9.52979 156.888 9.52979C155.465 9.52979 154.271 10.0688 153.308 11.1468C152.367 12.2247 151.897 13.6352 151.897 15.3783C151.897 17.1214 152.367 18.532 153.308 19.6099C154.271 20.6879 155.465 21.2269 156.888 21.2269C158.54 21.2269 159.836 20.7108 160.777 19.6787C161.19 19.22 161.5 18.704 161.706 18.1306H165.321C165.022 19.3003 164.495 20.3668 163.737 21.3301C161.993 23.4401 159.71 24.4952 156.888 24.4952C154.386 24.4952 152.298 23.6351 150.624 21.9149Z" fill="currentColor"/>
                    <path d="M169.543 21.9837C167.983 20.2865 167.203 18.0847 167.203 15.3783C167.203 12.6719 167.983 10.4816 169.543 8.80731C171.127 7.11009 173.1 6.26147 175.463 6.26147C176.955 6.26147 178.32 6.69725 179.559 7.5688C180.087 7.93576 180.557 8.36007 180.97 8.84176H181.142V6.60551H184.757V24.1511H181.142V21.7429H180.97C180.627 22.2245 180.156 22.6833 179.559 23.119C178.32 24.0365 176.955 24.4952 175.463 24.4952C173.1 24.4952 171.127 23.658 169.543 21.9837ZM172.365 11.0435C171.448 12.0527 170.989 13.4976 170.989 15.3783C170.989 17.259 171.448 18.704 172.365 19.7131C173.284 20.7222 174.546 21.2269 176.152 21.2269C177.667 21.2269 178.871 20.7222 179.766 19.7131C180.684 18.681 181.142 17.2361 181.142 15.3783C181.142 13.5206 180.684 12.0871 179.766 11.0779C178.871 10.0458 177.667 9.52979 176.152 9.52979C174.546 9.52979 173.284 10.0344 172.365 11.0435Z" fill="currentColor"/>
                    <path d="M188.874 24.151V0.0688477H192.488V20.8827H196.446V24.151H188.874Z" fill="currentColor"/>
                    <path d="M200.406 21.8805C198.685 20.1145 197.825 17.9471 197.825 15.3783C197.825 12.8096 198.685 10.6536 200.406 8.91055C202.149 7.14449 204.272 6.26147 206.773 6.26147C209.275 6.26147 211.386 7.14449 213.107 8.91055C214.85 10.6536 215.722 12.8096 215.722 15.3783V16.5824H201.438C201.713 18.0273 202.345 19.1627 203.332 19.9884C204.318 20.814 205.466 21.2269 206.773 21.2269C208.379 21.2269 209.664 20.8255 210.628 20.0227C211.064 19.7016 211.443 19.3003 211.764 18.8186H215.55C215.069 19.9425 214.46 20.8943 213.726 21.6741C211.845 23.5548 209.527 24.4952 206.773 24.4952C204.272 24.4952 202.149 23.6236 200.406 21.8805ZM201.61 13.6582H211.936C211.73 12.5113 211.179 11.5367 210.284 10.7339C209.412 9.93116 208.241 9.52979 206.773 9.52979C205.534 9.52979 204.432 9.9082 203.469 10.6651C202.505 11.399 201.885 12.3967 201.61 13.6582Z" fill="currentColor"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M33.4398 57.9206L51.4236 40.0709V6.49683H17.8496L0 24.4951H33.4254L33.4398 57.9206Z" fill="currentColor"/>
                  </g>
                </svg>
              </a>
            </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link className="outer marketing marketing-small" href=" https://ever.surf/" target="_blank"  onClick={() => setExpanded(false)}>Get Ever Surf</Nav.Link>
            </Nav>

          </Navbar>
        </header>
      </Container>
  );
};

export default Layout;
