import { FunctionComponent } from "react";
import { Container, Nav } from "react-bootstrap";

const Footer: FunctionComponent<{}> = () => {
  return (
    <footer>
      <Container fluid={"md"}>
        {/* <Nav as="ul">
          <Nav.Item as="li">
            <Link to="/legal-notes/surf-decentralization-policy" className="dod">Surf Decentralization Policy</Link>
          </Nav.Item>
        </Nav> */}
        <a target="_blank" rel="noreferrer" href="https://uri.ever.surf/chat/0:606545c3b681489f2c217782e2da2399b0aed8640ccbcf9884f75648304dbc77" className={"marketing marketing-small"}>Need help? Get help!</a>
        <div className="copyright">{(new Date()).getFullYear().toString()} &#169; <a href="https://everscale.network/" target="_blank" rel="noreferrer">Everscale</a></div>
      </Container>
    </footer>
  );
};

export default Footer;
