import gsap from "gsap";
import React, { useEffect, useState, ReactNode } from "react";
import { ConsoleView } from "react-device-detect";

const myObject = {
  totalValue: 0
};

const StatField:React.FunctionComponent<{value: number | undefined, title: string, digits?: number, postfix?: ReactNode, prefix?: ReactNode}> = ({value, title, digits = 0, postfix, prefix}) => {

  myObject.totalValue = 0;

  const multiplier:number = 10**digits;

  const [count, setCount] = useState(value ? value*multiplier : undefined);

  useEffect(() => {
    if (value) {
      gsap.to(myObject, {
        duration: 1,
        ease: "power4.out",
        totalValue: value*multiplier,
        roundProps: "totalValue",
        onUpdate: () => {
          setCount(myObject.totalValue);
        }
      });
    } else if (value === 0) {
      setCount(0);
    }
  }, [value, multiplier]);

  return (
    <div className={`stat-card`}>
      <div className="header paragraph paragraph-note">{title}</div>
      <div className="body marketing marketing-medium">{typeof count === 'number' ? <>{prefix ? prefix : ""}{(count/multiplier).toLocaleString('en', {minimumFractionDigits: digits})}{postfix ? postfix : ""}</> : "-"}</div>
    </div>
  );
}

export default StatField;