import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Accordion,
  AccordionContext,
} from "react-bootstrap";
import cn from "classnames";

import { useMain } from "../controllers/use-main";
import * as Yup from "yup";
import MetaDecorator from "../utils/metaDecorator";
import { Button } from "../components/button";
import dudes from "../assets/images/illustrations/3-dudes.png";
import heartful from "../assets/images/illustrations/heartful.png";
import { Icon } from "../components/icon";
import { Toast } from "../components/toast";
import StatField from "./stat-field";

const Logo: React.FunctionComponent<{}> = () => {
  return <div className="logo">Everstake</div>;
};

export const STAKING_RATE = 5.0;

const AccordionPanel: React.FunctionComponent<{
  title: string;
  eventKey?: any;
  callback?: any;
}> = ({ title, children, eventKey = "0", callback }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Accordion.Item
      eventKey={eventKey}
      className={isCurrentEventKey ? "active" : ""}
    >
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>{children}</Accordion.Body>
    </Accordion.Item>
  );
};

const Calculator: React.FunctionComponent<{}> = (props) => {
  const [period, setPeriod] = useState<number>(0);
  const [stake, setStake] = useState<number>(0);
  const profit = [
    [4, 9.5, 12, 14.5],
    [5, 7, 9, 11],
    [7, 9, 11, 13],
    [9, 11, 13, 15],
  ];
  return (
    <div className={"panel calculator"}>
      <h6 className={"marketing marketing-small"}>Lock period</h6>
      <Nav as="ul" className="calculator-row">
        <Nav.Item
          as="li"
          className={cn({ active: period === 0 })}
          onClick={() => setPeriod(0)}
        >
          1 year
        </Nav.Item>
        <Nav.Item
          as="li"
          className={cn({ active: period === 1 })}
          onClick={() => setPeriod(1)}
        >
          2 years
        </Nav.Item>
        <Nav.Item
          as="li"
          className={cn({ active: period === 2 })}
          onClick={() => setPeriod(2)}
        >
          3 years
        </Nav.Item>
        <Nav.Item
          as="li"
          className={cn({ active: period === 3 })}
          onClick={() => setPeriod(3)}
        >
          4 years
        </Nav.Item>
      </Nav>
      {/* <h6 className={"marketing marketing-small"}>Your stake</h6>
      <Nav as="ul" className="calculator-row">
        <Nav.Item as="li" className={cn({"active": stake === 0})} onClick={() => setStake(0)}>
          100&thinsp;–&thinsp;999&thinsp;Ē
        </Nav.Item>
        <Nav.Item as="li" className={cn({"active": stake === 1})} onClick={() => setStake(1)}>
          1,000&thinsp;–&thinsp;9,999
        </Nav.Item>
        <Nav.Item as="li" className={cn({"active": stake === 2})} onClick={() => setStake(2)}>
          10,000&thinsp;–&thinsp;99,999
        </Nav.Item>
        <Nav.Item as="li" className={cn({"active": stake === 3})} onClick={() => setStake(3)}>
          100K+
        </Nav.Item>
      </Nav> */}
      <h6 className={"marketing marketing-small"}>APR</h6>
      <div className="result">
        <div className={"headline headline-head"}>12%</div>
        <span>+</span>
        <div className={"headline headline-head"}>{profit[stake][period]}%</div>
        <span>=</span>
        <div className={"headline headline-head"}>
          {profit[stake][period] + 12}%
        </div>
        <a
          href="https://uri.ever.surf/debot/0:06479a3c94b089bf50f33308eb9e6263e8e2a67885c01f3566655b641da93ff3"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant="transparent"
            size="xl"
            className="marketing marketing-normal"
          >
            Stake now!
          </Button>
        </a>
      </div>
    </div>
  );
};

const Main: React.FunctionComponent<{}> = () => {
  const { stats } = useMain();
  console.log(stats);
  useEffect(() => {
    console.log(stats);
  }, [stats]);

  const [toast, setToast] = useState<boolean>(true);
  const promoStats = [
    {
      title: "Stakes",
      prefix: "",
      value: stats ? stats.TVL : undefined,
      postfix: <>&thinsp;Ē</>,
    },
    {
      title: "Participants",
      value: stats ? stats.totalStakeQty : undefined,
    },
    {
      title: "Everpools",
      value: stats ? stats.depoolQty : undefined,
    },
    {
      title: "Rewards",
      value: stats ? stats.remainingBonus : undefined,
      postfix: <>&thinsp;Ē</>,
    },
  ];
  const steps = [
    {
      title: "Install",
      value: [
        <a target="_blank" rel="noreferrer" href="https://ever.surf/download">
          Ever Surf
        </a>,
        <a target="_blank" rel="noreferrer" href="https://everspace.app">
          Everspace
        </a>,
      ],
    },
    {
      title: "Buy",
      value: [
        <a target="_blank" rel="noreferrer" href="https://uri.ever.surf/topup">
          Surf
        </a>,
        <a target="_blank" rel="noreferrer" href="https://www.mexc.com">
          MEXC
        </a>,
        <a target="_blank" rel="noreferrer" href="https://www.gate.io">
          Gate
        </a>,
      ],
    },
    {
      title: "Start",
      value: [
        <a
          target="_blank"
          rel="noreferrer"
          href="https://uri.ever.surf/debot/0:06479a3c94b089bf50f33308eb9e6263e8e2a67885c01f3566655b641da93ff3"
        >{`Boosted \nStaking \nDebot`}</a>,
      ],
    },
    {
      title: "Select",
      value: ["Amount,", "Lock period,", "Stake"],
    },
  ];
  return (
    <>
      <MetaDecorator
        title="Boosted staking. 26% APR. Safe. Secure. Profitable."
        description="Crypto staking program in Everscale network offers up to 26% APR. Reward withdrawal any time, completely decentralized token management by the user."
        keywords=""
      />
      <Toast
        header={
          <div className="title title-small2">
            <svg
              width="28"
              height="24"
              viewBox="0 0 28 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 13.7142V6.00254C14 6.00163 13.9989 6.00119 13.9983 6.00185L10 10.2858V17.9975C10 17.9984 10.0011 17.9988 10.0017 17.9981L14 13.7142Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M21 13.7142V6.00254C21 6.00163 20.9989 6.00119 20.9983 6.00185L17 10.2858V17.9975C17 17.9984 17.0011 17.9988 17.0017 17.9981L21 13.7142Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M7 13.7142V6.00254C7 6.00163 6.99889 6.00119 6.99827 6.00185L3 10.2858V17.9975C3 17.9984 3.00111 17.9988 3.00173 17.9981L7 13.7142Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinejoin="round"
              />
            </svg>
            Web 3 compatible
          </div>
        }
        qrcode={
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="currentColor"
          >
            <g>
              <path d="M14.9,0C14.4,0,14,0.4,14,0.9s0.4,0.9,0.9,0.9h0.9V0.9C15.8,0.4,15.4,0,14.9,0z" />
              <path d="M19.3,0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.9h0.9C18.9,1.8,19.3,1.4,19.3,0.9z" />
              <path d="M35.1,0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.9h0.9C34.7,1.8,35.1,1.4,35.1,0.9z" />
              <path d="M53.6,1.8c0.5,0,0.9-0.4,0.9-0.9S54,0,53.6,0c-0.5,0-0.9,0.4-0.9,0.9S53.1,1.8,53.6,1.8z" />
              <path d="M57.1,1.8c0.5,0,0.9-0.4,0.9-0.9S57.6,0,57.1,0c-0.5,0-0.9,0.4-0.9,0.9S56.6,1.8,57.1,1.8z" />
              <path
                d="M71.9,0.5c-0.1-0.2-0.2-0.3-0.4-0.4C71.2,0,71,0,70.4,0h-0.2h-1.8h-1.8H65h-1.8h-1.8h-0.2
            c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8v1.8V7v1.8v1.8v0.2c0,0.6,0,0.8,0.1,1.1
            c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8H65h1.8h1.8h1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            c0.1-0.2,0.1-0.5,0.1-1.1v-0.2V8.8V7V5.3V3.5V1.8V1.6C72,1,72,0.8,71.9,0.5z M70.2,3.5v1.8V7v1.8v1.8h-1.8h-1.8H65h-1.8h-1.8V8.8V7
            V5.3V3.5V1.8h1.8H65h1.8h1.8h1.8V3.5z"
              />
              <path
                d="M25.8,3.4C26,3.3,26.1,3.2,26.2,3c0.1-0.2,0.1-0.5,0.1-1.1V1.8h1.8H29c0.5,0,0.9-0.4,0.9-0.9S29.5,0,29,0h-0.9
            h-1.8h-1.8h-1.8H22c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h0.9h1.8v1.8h0.2C25.3,3.5,25.6,3.5,25.8,3.4z"
              />
              <path
                d="M40.4,1.8V1.6c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4C39.6,0,39.4,0,38.8,0h-0.2h-0.9
            c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h0.9v1.8h1.8V1.8z"
              />
              <path
                d="M50.4,3.4c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1V1.8V0.9C50.9,0.4,50.5,0,50,0
            c-0.5,0-0.9,0.4-0.9,0.9v0.9v1.8h0.2C49.9,3.5,50.2,3.5,50.4,3.4z"
              />
              <path
                d="M19.3,5.3V7h1.8h1.8h1.8h0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9V3.5h-1.8h-1.8h-1.8h-1.8V1.8
            h-1.8v1.8v1.8h1.8H19.3z"
              />
              <path
                d="M32.8,5.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1V3.5V1.8h-0.2c-0.6,0-0.8,0-1.1,0.1
            C32,2,31.8,2.1,31.7,2.3c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8h0.2C32.3,5.3,32.6,5.3,32.8,5.2z"
              />
              <path
                d="M67.9,3.6c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2H65h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4
            c-0.1,0.2-0.1,0.5-0.1,1.1v0.2V7v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1H65h1.8h0.2
            c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1V7V5.3V5.1c0-0.6,0-0.8-0.1-1.1C68.3,3.9,68.1,3.7,67.9,3.6z
            "
              />
              <path d="M14.9,5.3c-0.5,0-0.9,0.4-0.9,0.9C14,6.6,14.4,7,14.9,7h0.9V5.3H14.9z" />
              <path d="M45.5,5.8c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2V7h1.8V6.9C45.7,6.3,45.7,6,45.5,5.8z" />
              <path
                d="M4.1,8.7c0.2,0.1,0.5,0.1,1.1,0.1h0.2H7h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            C8.8,8,8.8,7.7,8.8,7.2V7V5.3V5.1c0-0.6,0-0.8-0.1-1.1C8.6,3.9,8.4,3.7,8.2,3.6C8,3.5,7.7,3.5,7.2,3.5H7H5.3H5.1
            c-0.6,0-0.8,0-1.1,0.1C3.9,3.7,3.7,3.9,3.6,4.1C3.5,4.3,3.5,4.6,3.5,5.1v0.2V7v0.2c0,0.6,0,0.8,0.1,1.1C3.7,8.4,3.9,8.6,4.1,8.7z"
              />
              <rect x="42.1" y="7" width="1.8" height="1.8" />
              <path
                d="M24.5,9.3C24.4,9.1,24.2,9,24,8.9c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8h-1.8V7h-1.8h-1.8v1.8h-0.2
            c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4C14,9.5,14,9.8,14,10.4v0.2v0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-0.9
            h1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2v-1.8h1.8v1.8h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h1.8v-0.2C24.6,9.8,24.6,9.5,24.5,9.3z"
              />
              <path
                d="M0.5,12.2c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8h1.8H7h1.8h1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            c0.1-0.2,0.1-0.5,0.1-1.1v-0.2V8.8V7V5.3V3.5V1.8V1.6c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4C11.5,0,11.3,0,10.7,0h-0.2H8.8
            H7H5.3H3.5H1.8H1.6C1,0,0.8,0,0.5,0.1C0.4,0.2,0.2,0.4,0.1,0.5C0,0.8,0,1,0,1.6v0.2v1.8v1.8V7v1.8v1.8v0.2c0,0.6,0,0.8,0.1,1.1
            C0.2,11.9,0.4,12.1,0.5,12.2z M1.8,8.8V7V5.3V3.5V1.8h1.8h1.8H7h1.8h1.8v1.8v1.8V7v1.8v1.8H8.8H7H5.3H3.5H1.8V8.8z"
              />
              <path
                d="M52.7,12.3h1.8v-1.8h1.8v-0.2c0-0.6,0-0.8-0.1-1.1C56,9.1,55.8,9,55.6,8.9c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8
            v1.8V12.3z"
              />
              <path d="M19.3,13.2c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-0.9h-1.8V13.2z" />
              <path
                d="M29.9,12.3v-1.8h1.8v1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h1.8
            v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2v-1.8h1.8v1.8h-1.8v0.2c0,0.6,0,0.8,0.1,1.1
            c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2v1.8h1.8h1.8v1.8h1.8h1.8v-1.8V14h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-1.8V8.8h1.8h1.8h1.8V7h1.8h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1V5.3V5.1c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1
            h-0.2h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1V7h-1.8V5.3V3.5h-1.8v1.8V7h-1.8v1.8h-1.8v1.8h1.8v1.8h-1.8v-1.8h-1.8v1.8V14
            h-1.8v-1.8v-1.8h1.8V8.8h-1.8V7h1.8V5.3h1.8V3.5V1.8h1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1
            h0.2V1.8V1.6c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4C46.7,0,46.4,0,45.8,0h-0.2h-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8h-1.8v1.8h-1.8V3.5h-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4C35.9,7,36.2,7,36.7,7
            h0.2v1.8h-1.8h-1.8h-1.8V7V5.3h-1.8V4.4c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.9v0.2c0,0.6,0,0.8,0.1,1.1
            c0.1,0.2,0.2,0.3,0.4,0.4C28.9,7,29.1,7,29.7,7h0.2v1.8h-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4
            c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8v1.8h-1.8v-1.8h-1.8v1.8V14v1.8h-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h0.9h1.8h0.9
            c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9V14h1.8h1.8h1.8v-1.8H29.9z"
              />
              <path
                d="M54.4,12.3V14h-1.8v-1.8h-1.8v-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.9V14v1.8H49
            c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8h1.8v1.8h1.8h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h1.8v0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-0.9v-1.8h1.8v-0.2
            c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4C58.9,14,58.7,14,58.1,14H58v-1.8v-0.2c0-0.6,0-0.8-0.1-1.1
            c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v1.8H54.4z"
              />
              <path
                d="M15.8,14.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9
            C15.4,15.8,15.8,15.4,15.8,14.9z"
              />
              <path d="M66.7,14.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.9h1.8V14.9z" />
              <path
                d="M71.1,14c-0.5,0-0.9,0.4-0.9,0.9v0.9h-1.8h-1.8v1.8H65v-1.8h-1.8v-0.9c0-0.5-0.4-0.9-0.9-0.9
            c-0.5,0-0.9,0.4-0.9,0.9v0.9h-1.8V16c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2v1.8h1.8v1.8h-1.8
            v-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2H58h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8v1.8H58h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            c0.1-0.2,0.1-0.5,0.1-1.1v-0.2H58v-1.8h1.8v1.8h1.8h1.8v-1.8H65v1.8h1.8v1.8H65v1.8h-1.8v0.2c0,0.6,0,0.8,0.1,1.1
            c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1H65v1.8h-1.8h-1.8h-1.8v-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4
            C58,30.6,58,30.9,58,31.5v0.2h-1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1H58h1.8v1.8h-0.2
            c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4C58,35.9,58,36.2,58,36.7v0.2h1.8v1.8H58v-1.8h-1.8h-0.9c-0.5,0-0.9,0.4-0.9,0.9
            c0,0.5,0.4,0.9,0.9,0.9h0.9v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1H58h1.8h0.2
            c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            c0.1-0.2,0.1-0.5,0.1-1.1v-0.2H65h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-1.8h1.8v-0.2
            c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-1.8h1.8h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1
            v-0.2v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v1.8h-1.8v-1.8h-1.8v-0.2
            c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1H65v-1.8h1.8h1.8v1.8v1.8h1.8v-1.8v-1.8h0.2
            c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-1.8v-0.9C72,14.4,71.6,14,71.1,14z"
              />
              <path d="M7,16.7c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v0.9H7V16.7z" />
              <path d="M19.3,16.7c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.9h0.9C18.9,17.6,19.3,17.2,19.3,16.7z" />
              <path
                d="M35,16.4c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-0.2c0-0.6,0-0.8-0.1-1.1
            c-0.1-0.2-0.2-0.3-0.4-0.4C32.6,14,32.3,14,31.8,14h-0.2v1.8V16c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4
            c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8v-0.2C35.1,16.8,35.1,16.6,35,16.4z"
              />
              <path
                d="M37.4,15.9c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2H37c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h-0.2C37.9,15.8,37.6,15.8,37.4,15.9z"
              />
              <path
                d="M8.8,19.3v0.2c0,0.6,0,0.8,0.1,1.1C9,20.7,9.1,20.9,9.3,21c0.2,0.1,0.5,0.1,1.1,0.1h0.2v-1.8h1.8v1.8H14v-1.8
            h1.8H16c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h-1.8H14h-1.8h-1.8v-1.8h0.9
            c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1
            v0.2v1.8H7v1.8H8.8z"
              />
              <path
                d="M36.9,17.6h-1.8v1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2v-1.8V17.6z
            "
              />
              <path d="M40.4,18.4c0,0.5,0.4,0.9,0.9,0.9h0.9v-1.8h-0.9C40.8,17.6,40.4,18,40.4,18.4z" />
              <path d="M45.7,18.4c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-0.9h-1.8V18.4z" />
              <path d="M43,21.1c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9v0.9C42.1,20.7,42.5,21.1,43,21.1z" />
              <path
                d="M11.7,22.7c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h-1.8v1.8h0.2C11.3,22.8,11.5,22.8,11.7,22.7
            z"
              />
              <path
                d="M18.4,21.1c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9
            C19.3,21.5,18.9,21.1,18.4,21.1z"
              />
              <path
                d="M50.9,22.8v-1.8v-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2H49
            c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8H50.9z"
              />
              <path
                d="M35,23.4c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2V22c0-0.5-0.4-0.9-0.9-0.9
            c-0.5,0-0.9,0.4-0.9,0.9v0.9V23c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8v-0.2
            C35.1,23.9,35.1,23.6,35,23.4z"
              />
              <path
                d="M53.6,24.6c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9h-1.8v1.8h-1.8h-1.8v1.8h-1.8v-1.8h1.8v-1.8h-1.8
            h-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8v1.8v1.8V30c0,0.6,0,0.8,0.1,1.1
            c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8h1.8h1.8H50c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-0.9v-1.8h0.2
            c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h1.8v-1.8H53.6z"
              />
              <path d="M12.3,25.5c0-0.5-0.4-0.9-0.9-0.9h-0.9v1.8h0.9C11.9,26.3,12.3,25.9,12.3,25.5z" />
              <rect x="63.2" y="24.6" width="1.8" height="1.8" />
              <path
                d="M21.1,28.1h-1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2v1.8v1.8h1.8
            h1.8v-1.8h1.8h1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2H30c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1
            v-0.2h1.8v-1.8v-1.8H35c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h-1.8h-1.8v-1.8v-1.8v-1.8
            v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v1.8v1.8v1.8v1.8v1.8h-1.8v-1.8v-1.8v-1.8v-1.8
            v-1.8h1.8v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4
            c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8h-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8
            V28.1z M24.6,29.9h-1.8v-1.8h1.8V29.9z"
              />
              <path
                d="M36.9,26.5c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4
            c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-1.8v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v1.8v1.8
            h-1.8v1.8h1.8V26.5z"
              />
              <path
                d="M52.7,26.3v1.8v1.8v1.8v1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2
            h1.8v-1.8v-1.8v-1.8h-1.8H52.7z"
              />
              <path d="M63.1,26.3c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8v-1.8H63.1z" />
              <path
                d="M60.9,29.7c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h0.2C60.4,29.9,60.7,29.9,60.9,29.7z"
              />
              <path d="M16.7,31.6c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-0.9v0.9C15.8,31.2,16.2,31.6,16.7,31.6z" />
              <path
                d="M68.6,32.8c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2v1.8v1.8h-1.8h-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4C65,37.6,65,37.9,65,38.5v0.2v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1
            h0.2v-1.8h1.8h1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4C72,37.9,72,37.6,72,37v-0.2v-1.8v-1.8v-0.2
            c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8v0.2C68.5,32.3,68.5,32.6,68.6,32.8z"
              />
              <path d="M26.3,35.1V35c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v1.8H26.3z" />
              <path d="M34.2,33.4c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9S34.7,33.4,34.2,33.4z" />
              <path
                d="M0.9,38.6c0.5,0,0.9-0.4,0.9-0.9v-0.9v-1.8v-0.9c0-0.5-0.4-0.9-0.9-0.9S0,33.8,0,34.2v0.9v1.8v0.9
            C0,38.2,0.4,38.6,0.9,38.6z"
              />
              <path
                d="M17.6,36.9h1.8v-1.8h1.8v-1.8h-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4
            c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2H14v-0.2
            c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8v-1.8h1.8h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-1.8h1.8v-1.8h1.8h1.8v-1.8h-1.8v-0.2c0-0.6,0-0.8-0.1-1.1
            c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-1.8v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4
            c-0.2-0.1-0.5-0.1-1.1-0.1H14v1.8v1.8v1.8v1.8h-1.8h-1.8v-1.8H8.8v-1.8h1.8v-1.8H8.8H7v-1.8v-1.8H5.3v-1.8H3.5H1.8H0.9
            C0.4,17.6,0,18,0,18.4c0,0.5,0.4,0.9,0.9,0.9h0.9v1.8h1.8h1.8v1.8H5.1c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4
            c-0.1,0.2-0.1,0.5-0.1,1.1v0.2H1.8v-1.8v-1.8H1.6c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4C0,21.8,0,22.1,0,22.7v0.2v1.8v1.8
            v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8v1.8H2.6c-0.5,0-0.9,0.4-0.9,0.9
            s0.4,0.9,0.9,0.9h0.9h1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4C7,30.8,7,30.6,7,30v-0.2h1.8h1.8h1.8v1.8h-1.8h-0.2
            c-0.6,0-0.8,0-1.1,0.1C9.1,31.8,9,32,8.9,32.2c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8v1.8v1.8v1.8h1.8h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h-1.8v-1.8h1.8v1.8H14h1.8v1.8h1.8h1.8v-1.8h-1.8V36.9z"
              />
              <path
                d="M31.6,38.5c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-0.2
            c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8v1.8h-1.8v-1.8h-1.8h-1.8v1.8h-1.8v1.8h1.8h1.8
            v1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8h0.9c0.5,0,0.9-0.4,0.9-0.9
            c0-0.5-0.4-0.9-0.9-0.9h-0.9v-1.8h1.8h1.8v1.8v0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-0.9h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h-1.8V38.5z"
              />
              <path d="M34.2,36.9c-0.5,0-0.9,0.4-0.9,0.9v0.9h1.8v-0.9C35.1,37.3,34.7,36.9,34.2,36.9z" />
              <path d="M14,41.3c0,0.5,0.4,0.9,0.9,0.9h0.9v-1.8h-0.9C14.4,40.4,14,40.8,14,41.3z" />
              <path d="M20.2,42.1c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9v0.9C19.3,41.8,19.7,42.1,20.2,42.1z" />
              <path d="M43,40.4c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h0.9v-1.8H43z" />
              <path
                d="M66.8,41.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8V42c0-0.6,0-0.8-0.1-1.1
            c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8v0.2C66.7,41.1,66.7,41.4,66.8,41.6z"
              />
              <path d="M71.1,43.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9v1.8H71.1z" />
              <path
                d="M15.6,43.9c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4C14,44.7,14,44.9,14,45.5v0.2v1.8h0.2
            c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h1.8h1.8v1.8v1.8h-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4
            c0.2,0.1,0.5,0.1,1.1,0.1h0.2v1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h1.8v-1.8h-1.8
            h-1.8v-1.8h1.8h1.8v-1.8v-1.8h1.8H23c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-0.2
            c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8h-1.8h-1.8v-0.2c0-0.6,0-0.8-0.1-1.1
            c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v1.8H15.6z"
              />
              <path
                d="M27.2,45.7c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9
            C26.3,45.3,26.7,45.7,27.2,45.7z"
              />
              <path
                d="M49.2,43.9h1.8v1.8v1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-1.8
            h1.8h1.8v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-0.9c0-0.5-0.4-0.9-0.9-0.9
            c-0.5,0-0.9,0.4-0.9,0.9v0.9h-1.8v-1.8v-1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-1.8
            v-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8h-1.8v-0.2c0-0.6,0-0.8-0.1-1.1
            c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8v-0.9c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v0.9h-1.8v1.8h-1.8
            v-1.8h1.8V35c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-1.8v-0.2c0-0.6,0-0.8-0.1-1.1
            c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8h-1.8V30c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4
            c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8v1.8v1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8
            h-1.8v1.8v1.8H35c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8h1.8v-1.8h1.8v1.8h1.8h1.8h1.8h1.8v0.9c0,0.5,0.4,0.9,0.9,0.9
            s0.9-0.4,0.9-0.9v-0.9v-1.8h1.8v1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2v-1.8V43.9z
            M47.4,36.9v1.8h-1.8v-1.8H47.4z M43.9,42.1v1.8h-1.8h-1.8h-1.8v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4
            c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-1.8H37c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h1.8h1.8h1.8
            v1.8h1.8v1.8H43.9z M47.4,40.4h1.8v1.8h-1.8V40.4z"
              />
              <path
                d="M63.2,45.7v-1.8V43c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.9h-1.8H58h-1.8v1.8H58v0.2
            c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8v-1.8H63.2z"
              />
              <path
                d="M68.3,43.9c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8h1.8v-1.8h-1.8
            H68.3z"
              />
              <path d="M12.3,46.5c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.9h1.8V46.5z" />
              <path d="M29.9,46.5c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-0.9h-0.9C30.2,45.7,29.9,46.1,29.9,46.5z" />
              <path d="M35.1,47.4h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h-1.8V47.4z" />
              <path
                d="M55,45.8c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8v1.8h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h-1.8v-1.8v-1.8v-1.8h-1.8v1.8h-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h-1.8V50c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.9v0.2
            c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8v1.8h1.8h1.8h1.8h1.8h1.8h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-1.8H58h0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9H58h-1.8v-1.8
            v-1.8H56C55.5,45.7,55.2,45.7,55,45.8z"
              />
              <path d="M65,47.4h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2H65h-1.8v1.8H65z" />
              <path d="M71.1,47.4c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9v1.8H71.1z" />
              <path d="M12.4,49.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h-1.8v1.8H12.4z" />
              <path d="M27.2,47.4c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h0.9v-0.9C28.1,47.8,27.7,47.4,27.2,47.4z" />
              <path
                d="M33.9,47.5c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8v1.8h-0.9c-0.5,0-0.9,0.4-0.9,0.9
            c0,0.5,0.4,0.9,0.9,0.9h0.9h1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2v-1.8h1.8v1.8h-1.8
            v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-1.8v-1.8v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-0.2
            c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8v-1.8H35C34.4,47.4,34.1,47.4,33.9,47.5z
            M36.9,50.9v1.8h-1.8v-1.8H36.9z"
              />
              <path
                d="M69,47.5c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2H65v-1.8v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4
            c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-1.8h-1.8v1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2v1.8
            v1.8H65v1.8h-1.8v-1.8h-1.8v1.8h-1.8H58h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1V58h-0.9
            c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h0.9v1.8H56c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4
            c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4C55.2,65,55.5,65,56,65h0.2v1.8v0.9
            c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-0.9h1.8h1.8v1.8h1.8v-1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            C65,66,65,65.7,65,65.1V65h1.8v0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V65v-1.8h-1.8H65v-1.8h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2V58v-1.8v-1.8h1.8v1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4
            c0.2,0.1,0.5,0.1,1.1,0.1h0.2v1.8v1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8V65
            v0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V65v-1.8v-1.8v-1.8V58v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4
            c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-0.2
            c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-1.8v-1.8h-0.2C69.5,47.4,69.2,47.4,69,47.5z
            M63.2,63.2h-1.8h-1.8H58v-1.8v-1.8V58h1.8h1.8h1.8v1.8v1.8V63.2z"
              />
              <path
                d="M5.3,50.9v-1.8H7v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8h1.8v-1.8
            h-1.8v-1.8H8.8v-1.8v-1.8h1.8h0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9H8.8v-1.8H7v-1.8v-0.2c0-0.6,0-0.8-0.1-1.1
            c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1H5.3v-1.8h0.9c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9H5.3H5.1
            c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8v1.8v1.8H1.8H1.6c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4C0,41.2,0,41.4,0,42v0.2v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2
            v0.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-0.9h1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1H7
            v1.8H5.3H3.5H1.8H1.6c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4C0,48.2,0,48.5,0,49v0.2v1.8v0.2c0,0.6,0,0.8,0.1,1.1
            c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4
            c0.2,0.1,0.5,0.1,1.1,0.1h0.2v-1.8V50.9z"
              />
              <path
                d="M22.8,52.7H23c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2V50
            c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.9h-1.8v1.8H22.8z"
              />
              <path
                d="M27.9,50.9c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8v1.8v0.2
            c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            c0.1-0.2,0.1-0.5,0.1-1.1v-0.2V56c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v-1.8h0.2
            c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4
            c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v1.8H27.9z"
              />
              <path
                d="M14,52.7v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v1.8h-1.8h-0.2
            c-0.6,0-0.8,0-1.1,0.1C9.1,52.9,9,53,8.9,53.2c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8h1.8v-1.8h1.8v1.8H14v-1.8V52.7z"
              />
              <path d="M60.6,52.7c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h0.9v-0.9C61.5,53.1,61.1,52.7,60.6,52.7z" />
              <path
                d="M7,56c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1H5.3v1.8H3.5H1.8v-0.9
            c0-0.5-0.4-0.9-0.9-0.9S0,54.8,0,55.3v0.9v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4C0.8,58,1,58,1.6,58h0.2h1.8h1.8H7h0.2
            c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2H7V56z"
              />
              <path
                d="M21.2,55.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h0.9c0.5,0,0.9-0.4,0.9-0.9
            c0-0.5-0.4-0.9-0.9-0.9h-0.9h-1.8v0.2C21.1,55.2,21.1,55.4,21.2,55.6z"
              />
              <path
                d="M44.4,54.5c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8v-1.8h-0.2
            C44.9,54.4,44.7,54.4,44.4,54.5z"
              />
              <path d="M10.5,57.1c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-0.9h-1.8V57.1z" />
              <path
                d="M14,56.2V58v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2V58H16
            c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h-1.8H14z"
              />
              <path d="M32.5,56.2c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h0.9v-0.9C33.4,56.6,33,56.2,32.5,56.2z" />
              <path
                d="M43.9,58v-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1V58v1.8H42
            c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8v0.2c0,0.6,0,0.8,0.1,1.1
            c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1
            v-0.2h-1.8v-1.8h1.8v1.8h1.8v-1.8h1.8h1.8v1.8v0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-0.9v-1.8v-0.2
            c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4C51.9,58,51.6,58,51.1,58h-0.2v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4
            c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8h-1.8V58H43.9z"
              />
              <path
                d="M11.7,59.8c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2H8.8H7H5.3H3.5H1.8H1.6c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4C0,60.5,0,60.7,0,61.3v0.2v1.8V65v1.8v1.8v1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4
            C0.8,72,1,72,1.6,72h0.2h1.8h1.8H7h1.8h1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-1.8
            v-1.8V65v-1.8v-1.8v-0.2c0-0.6,0-0.8-0.1-1.1C12.1,60.1,11.9,59.9,11.7,59.8z M10.5,63.2V65v1.8v1.8v1.8H8.8H7H5.3H3.5H1.8v-1.8
            v-1.8V65v-1.8v-1.8h1.8h1.8H7h1.8h1.8V63.2z"
              />
              <path
                d="M22.8,59.7h1.8v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4C23.8,58,23.5,58,23,58h-0.2h-1.8h-0.9
            c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h0.9v1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1
            h0.2h1.8v-1.8h-1.8V59.7z"
              />
              <path
                d="M28,60.3c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8v1.8h1.8h1.8v-0.2
            C28.1,60.7,28.1,60.5,28,60.3z"
              />
              <path
                d="M38.6,61.3c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2h-1.8v-0.2
            c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4C34.4,58,34.1,58,33.5,58h-0.2v1.8h-1.8h-0.2c-0.6,0-0.8,0-1.1,0.1
            c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h-1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4
            c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8v-1.8h1.8v1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h1.8
            v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2V65v0.2c0,0.6,0,0.8,0.1,1.1
            c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2v1.8h-1.8h-1.8v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4
            c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v1.8h-1.8v1.8h-1.8v-1.8h1.8v-1.8h1.8v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4
            C34.4,65,34.1,65,33.5,65h-0.2v-1.8h-1.8V65v1.8h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2
            h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8v0.2c0,0.6,0,0.8,0.1,1.1
            c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h1.8h1.8h1.8H37c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h1.8v0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-0.9H43c0.5,0,0.9-0.4,0.9-0.9
            c0-0.5-0.4-0.9-0.9-0.9h-0.9v-1.8h1.8h0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9h-1.8h-1.8v-1.8v-1.8h-1.8V61.3z"
              />
              <path
                d="M60.6,59.7c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9
            C61.5,60.1,61.1,59.7,60.6,59.7z"
              />
              <path
                d="M14.9,61.5c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h0.9H16c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v1.8H14.9z"
              />
              <path d="M48.3,63.2c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9v0.9C47.4,62.8,47.8,63.2,48.3,63.2z" />
              <path
                d="M8.2,63.3c-0.2-0.1-0.5-0.1-1.1-0.1H7H5.3H5.1c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4
            c-0.1,0.2-0.1,0.5-0.1,1.1V65v1.8v0.2c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2H7h0.2
            c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2V65v-0.2c0-0.6,0-0.8-0.1-1.1
            C8.6,63.6,8.4,63.4,8.2,63.3z"
              />
              <path
                d="M19.3,66.6c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4C18.6,65,18.3,65,17.7,65h-0.2h-0.2
            c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2v1.8v1.8v0.9c0,0.5,0.4,0.9,0.9,0.9
            c0.5,0,0.9-0.4,0.9-0.9v-0.9h1.8h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2v-1.8h-1.8V66.6z"
              />
              <path
                d="M22.8,65h-0.2c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8v0.2
            c0,0.6,0,0.8,0.1,1.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9v-0.2c0-0.6,0-0.8-0.1-1.1
            c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2V65H22.8z"
              />
              <path
                d="M53.6,66.7h-0.9v-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v0.9v0.2c0,0.6,0,0.8,0.1,1.1
            c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.1,1.1,0.1h0.2h0.9c0.5,0,0.9-0.4,0.9-0.9C54.4,67.1,54,66.7,53.6,66.7z"
              />
              <path d="M48.3,68.5c-0.5,0-0.9,0.4-0.9,0.9v0.9h0.9c0.5,0,0.9-0.4,0.9-0.9C49.2,68.9,48.8,68.5,48.3,68.5z" />
              <path d="M61.3,68.5c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2h1.8v-1.8H61.3z" />
              <path d="M65,70.2v-0.2c0-0.6,0-0.8-0.1-1.1c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-1.1-0.1h-0.2v1.8H65z" />
              <path
                d="M25.5,70.2c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h0.9h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h-1.8H25.5z"
              />
              <path d="M45.7,71.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-0.9h-0.9C46.1,70.2,45.7,70.6,45.7,71.1z" />
              <path
                d="M53.6,70.2h-0.9h-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h0.9h0.9c0.5,0,0.9-0.4,0.9-0.9
            C54.4,70.6,54,70.2,53.6,70.2z"
              />
              <path
                d="M57.1,70.2c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9H58h0.2c0.6,0,0.8,0,1.1-0.1c0.2-0.1,0.3-0.2,0.4-0.4
            c0.1-0.2,0.1-0.5,0.1-1.1v-0.2H58H57.1z"
              />
              <path d="M61.5,71.1c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-0.9h-1.8V71.1z" />
              <path
                d="M65.1,71.5c0.1,0.2,0.2,0.3,0.4,0.4C65.7,72,66,72,66.6,72h0.2h0.2c0.6,0,0.8,0,1.1-0.1
            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.5,0.1-1.1v-0.2h1.8h0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.9h-1.8h-0.2
            c-0.6,0-0.8,0-1.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,1.1v0.2H65v0.2C65,71,65,71.2,65.1,71.5z"
              />
            </g>
          </svg>
        }
        close={true}
        variant={"light"}
        show={toast}
        onClose={() => {
          setToast(false);
        }}
      >
        <a
          href="https://uri.ever.surf/debot/0:06479a3c94b089bf50f33308eb9e6263e8e2a67885c01f3566655b641da93ff3"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          <div className="action action-normal">
            Boosted Staking Debot&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div className="parapgraph parapgraph-small">
            Address: 0:064 ···· 3ff3
          </div>
        </a>
      </Toast>
      <div className="gray-back">
        <Container fluid={"md"}>
          <Row>
            <Col
              xs={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
            >
              <div className="hero">
                <div className="header marketing marketing-large">
                  <span>Limited</span>
                  <span>Offer!</span>
                  <div className="subheader marketing marketing-small">
                    Safe. Secure. Profitable.
                  </div>
                </div>

                <div className="percentage">
                  <div className="subheader marketing marketing-normal">
                    Up to
                    <b className="subheader marketing marketing-huge">
                      26%
                    </b>{" "}
                    APR
                  </div>
                  <div className="subheader marketing marketing-small">
                    Boosted staking program
                  </div>
                </div>
                <a
                  href="https://uri.ever.surf/debot/0:06479a3c94b089bf50f33308eb9e6263e8e2a67885c01f3566655b641da93ff3"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    variant="primary"
                    className="marketing marketing-normal"
                  >
                    <span>Stake</span>
                  </Button>
                </a>
              </div>
            </Col>
          </Row>
          {stats ? (
            <>
              <Row className="stat-cards d-flex d-lg-none">
                {promoStats.map((item, index) => (
                  <Col
                    key={index}
                    xs={{ span: 6, order: 1 }}
                    sm={{ span: 6, order: 1 }}
                    md={{ span: 6, order: 1 }}
                    lg={{ span: 3 }}
                    xl={{ span: 3 }}
                  >
                    <StatField
                      title={item.title}
                      value={item.value || 0}
                      prefix={item.prefix}
                      postfix={item.postfix}
                    />
                  </Col>
                ))}
              </Row>
              <Row className="stat-cards stat-cards-flex d-none d-lg-flex">
                <Col xs={{ span: 12 }}>
                  {promoStats.map((item, index) => (
                    <StatField
                      key={index}
                      title={item.title}
                      value={item.value || 0}
                      prefix={item.prefix}
                      postfix={item.postfix}
                    />
                  ))}
                </Col>
              </Row>
            </>
          ) : (
            <Row className="stat-cards">
              {promoStats.map((item, index) => (
                <Col
                  key={index}
                  xs={{ span: 6, order: 1 }}
                  sm={{ span: 6, order: 1 }}
                  md={{ span: 6, order: 1 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                >
                  <StatField
                    title={item.title}
                    value={undefined}
                    postfix={item.postfix}
                  />
                </Col>
              ))}
            </Row>
          )}
        </Container>
      </div>
      <Container fluid={"md"} className="about">
        <Row>
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            md={{ span: 12, order: 1 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
          >
            <h3 className="marketing marketing-small">About Everscale</h3>

            <p>
              Everscale Network is the only end-to-end decentralized framework
              that renegotiates the implicit tradeoff between privacy and
              convenience in modern internet applications.
            </p>
            <p>
              Driven by its distributed global community, Everscale is dedicated
              to empowering communities to explore, build and develop
              decentralized applications without compromising personal autonomy
              in favor of usability.
            </p>

            <a
              href="https://everscale.network"
              target="_blank"
              rel="noreferrer"
              className="outer marketing marketing-small"
            >
              Everscale Blockchain
            </a>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            md={{ span: 12, order: 1 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
          >
            <img
              className="image-dudes"
              alt="About everscale promo"
              src={dudes}
            />
          </Col>
        </Row>
      </Container>
      <div className="gray-back profits">
        <Container fluid={"md"}>
          <Row>
            <Col
              xs={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 5 }}
              xl={{ span: 6 }}
            >
              <h3 className="marketing marketing-small">
                Staking &amp; Profits
              </h3>

              <p>
                Staking is the best way to accumulate tokens. Farming has
                impermanent losses, staking doesn’t. Lesser risks, higher
                stability.
              </p>
              <p>
                Traditional staking in the Everscale network brings less than
                12% APR. Boosted staking program can deliver up to 26% APR
                depending on the stake size and lock period.
              </p>

              <a
                href="https://ever.surf/staking"
                target="_blank"
                rel="noreferrer"
                className="outer marketing marketing-small"
              >
                Surf Stakes
              </a>
            </Col>
            <Col
              className={"calculator-wrapper"}
              xs={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 7 }}
              xl={{ span: 6 }}
            >
              <Calculator />
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid={"md"} className="steps">
        <Row>
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            md={{ span: 12, order: 1 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
          >
            <h3 className="marketing marketing-small">Easy steps to success</h3>

            <div className="step-cards">
              {steps.map((item, index) => (
                <div key={index} className="step-card">
                  <div className="header marketing marketing-normal">
                    {item.title}
                  </div>
                  <div className="body">
                    {item.value.map((litem, index) => (
                      <span key={index}>{litem}</span>
                    ))}
                  </div>
                </div>
              ))}

              <div className="step-card step-card-slogan">
                <div className="header"></div>
                <div className="slogan marketing marketing-medium">
                  <a
                    href="https://uri.ever.surf/debot/0:06479a3c94b089bf50f33308eb9e6263e8e2a67885c01f3566655b641da93ff3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Enjoy <em className="marketing marketing-normal">the</em>{" "}
                    <span>ride!</span>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="gray-back">
        <Container fluid={"md"} className="debots">
          <Row>
            <Col
              xs={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}
            >
              <h3 className="marketing marketing-small">
                Debots &mdash; What are they all about?
              </h3>

              <p>
                Simply put, Debots (or, Decentralized Bots) are the future
                of decentralized communication.{" "}
              </p>
              <p>
                The friendly user interface in the Surf Browser makes the
                process of staking simple, easy and quick. Plus, it’s completely
                secure as you do not need to pass any keys or seed phrases
                to third parties.
              </p>

              <a
                href="https://uri.ever.surf/debot/0:66cb703cd63dd0b9eafbce702a9f838211ba1ea5ccce101dc81b98114d824b8a"
                target="_blank"
                rel="noreferrer"
                className="outer marketing marketing-small"
              >
                Everstaking Debot
              </a>
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 6 }}
              xl={{ span: 6 }}
            >
              <img
                className="image-heartful"
                alt="About Debots promo"
                src={heartful}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid={"md"}>
        <h3 className="marketing marketing-small">FAQ</h3>
        <Accordion defaultActiveKey="-1" className={"content-wrapper"}>
          <AccordionPanel eventKey="0" title="What is staking?">
            <p>
              Staking allows you to get passive income from the EVERs you are
              holding. Send your EVER tokens to a Depool smart contract where
              they will help to validate blockchain transactions.
            </p>
          </AccordionPanel>
          <AccordionPanel
            eventKey="1"
            title="Boosted staking &mdash; what is that?"
          >
            <p>
              Boosted Staking is a limited-time program that lets you stake EVER
              tokens and receive additional APR. Current staking APR provided by
              the network is{" "}
              <a
                href="https://ever.surf/staking"
                target="_blank"
                rel="noreferrer"
              >
                around 12&thinsp;%
              </a>
              , while boosted staking can offer up to 26&thinsp;% additional
              annual percentage yield.
            </p>
          </AccordionPanel>
          <AccordionPanel
            eventKey="2"
            title="How do i participate in boosted staking program?"
          >
            <p>
              Follow the following easy steps to start participating in the
              Boosted Staking of EVER tokens:
            </p>
            <Row>
              <Col md={8}>
                <ol>
                  <li>
                    Install{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://ever.surf/download"
                    >
                      Ever Surf
                    </a>{" "}
                    or{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://everspace.app"
                    >
                      Everspace
                    </a>
                  </li>
                  <li>
                    Deploy your wallet with{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://https://uri.ever.surf/debot/0:533c5450e11ed2aad36cce68f3326692bdb32141a01fe53e17effc2e91daa4a8"
                    >
                      Activation Debot
                    </a>{" "}
                    if you’re using Ever Surf
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://pay.ever.surf/"
                    >
                      Buy some EVER tokens at Surf
                    </a>
                    , <a href="https://payments.surf/swap">swap tokens</a> or{" "}
                    <a href="https://payments.surf/getever">
                      use any other exchange
                    </a>{" "}
                    of your liking{" "}
                  </li>
                  <li>
                    Open Surf and launch{" "}
                    <a
                      href="https://uri.ever.surf/debot/0:06479a3c94b089bf50f33308eb9e6263e8e2a67885c01f3566655b641da93ff3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Boosted&nbsp;Staking&nbsp;Debot
                    </a>{" "}
                    or open{" "}
                    <a
                      href="https://everspace.app/deeplink?type=boosted_staking"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Everspace
                    </a>
                  </li>
                  <li>
                    Follow the dialogue and stake your EVER tokens with a
                    boosted APR
                  </li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                That's all. Your stake begins earning you rewards when the next
                validation cycle starts.
                <br />
                <br />
              </Col>
            </Row>
          </AccordionPanel>
          <AccordionPanel eventKey="3" title="Is staking safe?">
            <p>
              Both Boosted Staking and ordinary Staking in Everscale network are
              performed by sending your tokens to the Depool smart contract.
              Your tokens are held on this control and nobody has any control of
              them except the contract itself and you. The Depool contract has
              been formally verified means that it is not possible for any third
              party or network developer to hack and steal your tokens.
            </p>
          </AccordionPanel>
          <AccordionPanel eventKey="5" title="How much should I stake?">
            <p>
              That depends on the number of tokens you’re ready to lock for a
              selected time period. We would suggest staking and locking them
              all for the longest lock period - this will guarantee that you
              will receive the highest amount of rewards.
            </p>
          </AccordionPanel>
          <AccordionPanel eventKey="6" title="When can I withdraw my tokens?">
            <p>
              You choose the lock period manually. When this period is over, you
              can withdraw your tokens. Be advised that the transfer of the
              tokens from the Depool to your wallet might take up to 54 hours.{" "}
            </p>
          </AccordionPanel>
        </Accordion>
      </Container>
      <div className="gray-back social">
        <Container fluid={"md"}>
          <ul>
            <li className="icon twitter">
              <a
                href="https://twitter.com/Everscale_net"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="twitter" />
              </a>
            </li>
            <li className="icon github">
              <a
                href="https://github.com/tonlabs/tondev/blob/main/docs/quick_start.md"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="github" />
              </a>
            </li>
            <li className="icon discord">
              <a
                href="https://discord.gg/eXTWaAEPaV"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="discord" />
              </a>
            </li>
            <li className="icon telegram">
              <a
                href="https://t.me/EVERSCALE_comm"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="telegram" />
              </a>
            </li>
            <li className="icon youtube">
              <a
                href="https://www.youtube.com/channel/UCPVBkzUuMD5TlQDGUqyWzzg"
                target="_blank"
                rel="noreferrer"
              >
                <Icon icon="youtube" />
              </a>
            </li>
          </ul>
        </Container>
      </div>
    </>
  );
};

export default Main;
