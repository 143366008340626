import React, { useRef } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import cn from "classnames";
import {
  isMobile
} from "react-device-detect";

import Main from "./main";
import Content from "./content";
import Footer from "./footer";
import Header from "./header";
import { Scrollspy } from "../utils/Scrollspy";

const Router:React.FunctionComponent<{}> = () => {
  const sectionRefs = [
    useRef<HTMLDivElement>(null),
  ];
  const location:string = useLocation().pathname.split('/').filter(Boolean)[0];
  return (
    <Scrollspy sectionRefs={sectionRefs}>
      {({ elementsScrolled }) => (
    <div className={cn("ws-app", location, {"isMobile": isMobile, "index-page": !location})}>

      <div className="gray-back">
        <Header elementsScrolled={elementsScrolled}/>
      </div>
      <main ref={sectionRefs[0]}>
        <Switch>
          <Route path="/legal-notes/:id">
            <Content />
          </Route>
          <Route render={(props) => <Main/>} />
          <Redirect to='/'/>
        </Switch>
      </main>
      <Footer />
    </div>
    )}
    </Scrollspy>
  );
};

export default Router;
