import { Dispatch, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import DeBotClient from "../client/client";

import { ActionTypes, RootState } from "../types";
import { 
  Stats
 } from "../interfaces";
import {
  setStats,
} from "../redux/actions";
import { number } from "yup/lib/locale";

export const MAX_DEPOOL_CAPACITY = 1033000;
export const DEPOOL_ROUNDS = 240;

export interface IUseMain {
  stats: Stats | undefined;
}

export const useMain = (): IUseMain => {
  const dispatch = useDispatch<Dispatch<ActionTypes>>();
  const { stats } = useSelector((state: RootState) => state.dashboard);

  const tonsToReadableFloat = (value: number): number => value/(10**9);
  
  const calculateProfit = (percent: number, rounds: number): number => {
    if (rounds) return (1 + percent / 100) ** rounds;
    return 0;
  }

  useEffect(() => {
    if (!stats) {
      DeBotClient.getStats()
        .then((value: any) => {
          try {
            const data = typeof value === 'string' ? JSON.parse(value) : value.stats;
            dispatch(setStats({
              TVL: tonsToReadableFloat(parseInt(data.TVL)),
              remainingBonus: tonsToReadableFloat(parseInt(data.remainingBonus)),
              paidBonus:  tonsToReadableFloat(parseInt(data.paidBonus)),
              participantQty:  parseInt(data.participantQty),
              depoolQty:  parseInt(data.depoolQty),
              totalStakeQty:  parseInt(data.totalStakeQty),
              lockedStakeQty:  parseInt(data.lockedStakeQty),
              periodSlice: data.periodSlice,
              stakeSlice: data.stakeSlice
            }));
            const ordered = Object.keys(value.all).sort((a, b) => value.all[b].metrics.depooler - value.all[a].metrics.depooler).reduce(
              (obj: any, key: any) => { 
                obj[key] = value.all[key]; 
                return obj;
              }, 
              {}
            );
            dispatch(setStats(ordered));
          } catch (error) {
            console.warn(value);
            console.error(error);
          }
        });
    }
  }, [stats]);


  return useMemo(
    () => ({
      stats,
    }),
    [
      stats
    ],
  );
};
