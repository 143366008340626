import callback from "./abi";
import moment from "moment";
import { 
  Stats,
  DeBot,
 } from "../interfaces";
const browser = import("debot-browser");

(async () => {
  console.log((await browser).run_debot_browser);
})()

const logger = console;

// export const DeBots:Array<DeBot> = [
//   {
//     title: 'Devnet',
//     name: 'net.ton.dev',
//     address: '0:e47e0efbd07d588e5bcf820221cd976527ddcc59b3a9760144a1ad72d5257ac9'
//   },
// ]

export const DeBots:Array<DeBot> = [
  {
    title: 'Mainnet',
    name: 'https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d',
    address: '0:f207fcab308c52a75226212d58dcfbc146cc7c1df1be16046200432f062a0822'
  }
]

export default class DeBotClient {
  static convert = (from: any, to: any) => (data: any) => Buffer.from(data, from).toString(to);

  static hexToUtf8 = DeBotClient.convert('hex', 'utf8');
  static utf8ToHex = DeBotClient.convert('utf8', 'hex');


//  private static debot = "0:e47e0efbd07d588e5bcf820221cd976527ddcc59b3a9760144a1ad72d5257ac9";
  private static debot = "0:c992a2ec05aafa04b1022a796793506f367c3cf5ad16481cff5fabedb0ffc874";
  private static buildManifest = (func: any, args: any) => {
  return `{
    "version": 0,
    "debotAddress": "${DeBotClient.debot}",
    "initMethod": "${func}",
    "initArgs": ${args},
    "quiet": true,
    "chain": [],
    "abi": ${callback.abi}
  }`;
  };

  static async getStats(debot: DeBot = DeBots[0]):Promise<Stats> {
    logger.log(`Calling getStats...\n`);
    const manifest = DeBotClient.buildManifest("invokeGetStats", "{}");
    const result = (await browser).run_debot_browser(debot.name, null, null, null, manifest)
    .then(result => JSON.parse(result))
    .catch(error => error);
    return await result;
  }
}