import { 
  SET_STATS
} from "./constants";

import {
  Stats,
} from "../../interfaces";

export const setStats = (value: Stats) =>
  ({
    type: SET_STATS,
    payload: value,
  } as const);
