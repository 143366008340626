import { ActionTypes } from "../../types/action-types";
import {
  SET_STATS
} from "../actions/constants";

import {
  Stats
} from "../../interfaces";

interface IInitialState {
  stats: Stats | undefined,
}

const initialState: IInitialState = {
  stats: undefined
};

export const dashboardReducer = (
  state: IInitialState = initialState,
  { type, payload }: ActionTypes
):IInitialState => {
  switch (type) {
    case SET_STATS:
      return { ...state, stats: payload as Stats};
    default:
      return state;
  }
};